export const fetchContractByName = async (contractName) =>
    await fetch(`/api/contracts/${contractName}`).then(res => res.json()).then(contract => contract);

export const fetchGalleryByContractAndWallet = async (contractAddress, wallet, offset = 0) =>
    fetch(`/api/gallery/${contractAddress}/${wallet}?offset=${offset}`).then(res => res.json()).then(data => data.assets)

export const fetchGalleryByContract = async (contractAddress, offset = 0, limit = 12) =>
    await fetch(`/api/avatracks_gallery/${contractAddress}?offset=${offset}&limit=${limit}`).then(res => res.json()).then(data => data.assets)

export const createLike = async (message, signature) =>
    fetch("/api/like/create", {
        method: 'POST',
        body: JSON.stringify({
            message: message,
            signature: signature
        }),
        headers: {'Content-Type': 'application/json'},
    }).then(res => res.json()).then(json => json);

export const postUpdateAsset = async (message, signature) =>
    fetch('/api/gallery/update_asset', {
        method: 'POST',
        body: JSON.stringify({
            message: message,
            signature: signature
        }),
        headers: {'Content-Type': 'application/json'},
    }).then(res => res.json()).then(json => json);

export const postUpdateAssetVisible = async (message, signature) =>
    fetch('/api/gallery/update_asset_visibility', {
        method: 'POST',
        body: JSON.stringify({
            message: message,
            signature: signature
        }),
        headers: {'Content-Type': 'application/json'},
    }).then(res => res.json()).then(json => json);

export const getAssetImageWithoutBackground = async (contractAddress, tokenId, message, signature) =>
    fetch(`/serve/additional_assets/${contractAddress}/${tokenId}`, {
        method: 'POST',
        body: JSON.stringify({
            message: message,
            signature: signature
        }),
        headers: {'Content-Type': 'application/json'},
    }).then(res => res.blob()).then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${tokenId}.zip`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });

export const getGalleryAsset = async (contractName, tokenId, avatrackContractName=null) =>
    await fetch(`/api/gallery_asset/${contractName}/${tokenId}${avatrackContractName ? "?avatracks_contract_name=" + avatrackContractName : ""}`)
        .then(res => res.json())
        .catch(() => null);

export const getAnitokenContract = async (lola = false) =>
    await fetch(`/api/anitoken/mint${lola ? "?what_is_lola=lola_is_the_best_dog_ever" : ""}`)
        .then(res => res.json()).then(json => json);


export const getAvatracksContract = async (lola = false) =>
    await fetch(`/api/avatracks/mint${lola ? "?what_is_lola=lola_is_the_best_dog_ever" : ""}`)
        .then(res => res.json()).then(json => json);

export const getHovercarsContract = async (lola = false) =>
    await fetch(`/api/hovercars/mint${lola ? "?what_is_lola=lola_is_the_best_dog_ever" : ""}`)
        .then(res => res.json()).then(json => json);

export const fetchGalleryBatch = async (contractAddress, tokens_ids) =>
    await fetch(`/api/gallery_batch/${contractAddress}?${tokens_ids.map(t => "token_ids=" + t).join("&")}`).then(res => res.json()).then(data => data.assets);

export const fetchTraits = async (token_id) =>
    await fetch(`/api/avatracks/layers/${token_id}`).then(res => res.json());

export const fetchUnmintedAvatracksTokenIds = async (wallet) =>
    await fetch(`/api/avatracks/unminted_tokens/${wallet}`).then(res => res.json());

export const fetchUnmintedHovercarsTokenIds = async (wallet) =>
    await fetch(`/api/hovercars/unminted_tokens/${wallet}`).then(res => res.json());