import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ThemeProvider} from './hooks/themeHook/themeContext';
import "./fonts/JetBrainsMono-Regular.ttf"

const Root = () => {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

reportWebVitals();
serviceWorkerRegistration.unregister();
