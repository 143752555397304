import s from './GalleryCard.module.scss';
import {useLocation} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import placholder from '../../../assets/placeholder.png';
import React, {useEffect, useState} from "react";
import openseLogo from "../../../assets/opensea-icon-3.svg"
import {FiEdit2} from 'react-icons/fi';
import {BsCloudDownload} from "react-icons/bs"
import cx from "classnames";
import {getAssetImageWithoutBackground} from "../../../conectors/animeta_api/animeta_api";
import {truncate} from "../../../utils/truncate";
import {FaTwitter} from "react-icons/fa";
import {IoGift as GiftIcon} from "react-icons/io5";
import {generatePublicAssetPageTweet} from "../../../utils/twitterGenerator";
import Switch from "@mui/material/Switch";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import SoundWaveBtn from "../../../components/SoundWaveBtn/SoundWaveBtn";

const GalleryCard = ({contract_address, token_id, opensea_url, img_url, thumbnail_url, name, description, background_story, onClick, wallet, visible, onClickEdit, contractName, handleVisibility, ava_track_url, ava_track_minted, onClickMintClaimAvaTracksHovercar, avatracksMintPaused, handleisAnimetasMinted, assetPagePath, hoverboardClaimPaused, handleisAnimetasClaimedHovercars, onClickClaimHovercars, onClickMintAvaTracks}) => {
    const location = useLocation();

    const [overlay, setOverlay] = useState(false);
    const [visibleClass, setVisibleClass] = useState("visible");
    const [avatrackMinted, setAvatrackMinted] = useState(false);
    const [hoverboardClaimed, setHoverboardClaimed] = useState(false);

    useEffect(() => {
        setVisibleClass(visible ? "switchVisible" : "switchInvisible")
    }, [visible]);

    useEffect(() => {
        checkAvatrackMinted();
    }, []);

    useEffect(() => {
        checkHoverboardsClaimed();
    }, [hoverboardClaimed])

    const downloadAssetWithoutBackground = async () => {
        const message = JSON.stringify({
            token_id: token_id,
            contract_address: contract_address,
        });

        const signature = await window.web3.eth.personal.sign(message, wallet);
        await getAssetImageWithoutBackground(contract_address, token_id, message, signature);
    };

    const handleUpdateVisible = (event) => {
        event.preventDefault();
        handleVisibility(contract_address, token_id, event.target.checked);
    };

    const renderOverly = () => {
        return (<div className={s.overlay}>
            <div className={s.overlyContent}>
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className="d-flex flex-row align-items-center">
                        <a onClick={(e) => e.stopPropagation()} target="_blank"
                           href={generatePublicAssetPageTweet(contractName, token_id, name)}>
                            <FaTwitter/>
                        </a>
                        <a onClick={(e) => e.stopPropagation()} href={opensea_url} target="_blank"
                           title="Open in OpenSea">
                            <img alt="Logo" className={s.overlayBtn} src={openseLogo}/>
                        </a>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <BsCloudDownload className={cx(s.overlayBtn, s.download)}
                                         title="Download Assets"
                                         onClick={async (e) => {
                                             e.stopPropagation();
                                             await downloadAssetWithoutBackground();
                                         }}/>
                        <FiEdit2 className={s.overlayBtn} title="Edit" onClick={(e) => {
                            e.stopPropagation();
                            onClickEdit();
                        }}/>
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-end w-100">
                </div>
            </div>
        </div>)
    };

    const handleMintClaimAvaTrackHovercar = (e) => {
        e.stopPropagation();
        onClickMintClaimAvaTracksHovercar(token_id, isNotMintAvatrack(), isNotClaimHovercar())
    }

    const claimHoverBoard = (e) => {
        e.stopPropagation();
        onClickClaimHovercars()
    }

    const checkAvatrackMinted = async () => {
        let result = await handleisAnimetasMinted();
        setAvatrackMinted(result);
    };

    const checkHoverboardsClaimed = async () => {
        let result = await handleisAnimetasClaimedHovercars();
        setHoverboardClaimed(result);
    };

    const renderMintAvaTrackClaimHovercarButtons = () =>
        <div className={s.mintAvatrackButtonsWrapper} title={"Mint AnimeTracks & Claim Hovercar in 1 transaction"}>
            {
                (isNotMintAvatrack()) &&
                <div className={cx("action-button blur-background")} onClick={(e) => handleMintClaimAvaTrackHovercar(e)}>
                    <div>MINT AnimeTrack</div>
                </div>
            }
        </div>

    const renderClaimHoverboardsButtons = () =>
        <div
            title={"Claim Hovercar Tooltip - This will claim your hovercar! If you already have an AnimeTrack your Hovercar will have music. If you don't you can mint it later and we will take care of that!"}>
            {isNotClaimHovercar() &&
            <div className={cx("action-button blur-background")} onClick={(e) => claimHoverBoard(e)}>
                CLAIM Hovercar
            </div>
            }
        </div>

    const isNotClaimHovercar = () => {
        return !hoverboardClaimed && !hoverboardClaimPaused
    }
    const isNotMintAvatrack = () => {
        return !avatrackMinted && !avatracksMintPaused
    }

    const renderOverly2 = () => {
        return (<div className={cx(s.overlay, "h-100")}>
            <div className={s.overlyContent}>
                <div className="d-flex flex-row justify-content-between w-100"></div>

                <div className="d-flex flex-row justify-content-end w-100">
                    {renderMintAvaTrackClaimHovercarButtons()}
                </div>
            </div>
        </div>)
    };

    return (
        <li className={s.card}>

            <div className={s.cardWrapper}>
                <button onClick={() => onClick()}
                        onMouseOver={e => setOverlay(true)}
                        onMouseLeave={e => setOverlay(false)}>

                    <div className={s.imgWrapper}>
                        {renderOverly2()}
                        {overlay && renderOverly()}

                        <LazyLoadImage
                            alt="card-img"
                            effect="blur"
                            src={img_url}
                            // src={"https://lh3.googleusercontent.com/teKXPn5r3lWyqPBi5IeBtXU1Ru8tWF-iImtTY-_akyKx1PgTeZHI5UTSwpWBjcodMlCCZH9dZtpZ3IDMtefSbI4T7d03cqT_jdaV4k4=w600"}
                            width="100%"
                            style={{minHeight: '10rem'}}
                            placeholderSrc={placholder}
                            className={s.img}
                            title={`#${token_id}`}
                        />
                    </div>
                </button>

                <div className={s.cardBody}>
                    <div className={"w-100"}>
                        <h3 className={s.title} title={name}>{truncate(name, 20)}</h3>
                        {avatrackMinted &&
                        <div
                            className={cx("action-button-small pink-blur-brightness-background w-60", s.accessMusicalBtn)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(assetPagePath, '_blank').focus();
                            }}>
                            Access Musical Traits
                        </div>
                        }
                        <p className={s.description} title={description}>{truncate(description, 33)}</p>
                        <p className={s.backgroundStory} title={background_story}>{background_story}</p>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className={s.visibilitySection}>
                            <Switch defaultChecked className={"mright-0.5"} checked={visible}
                                    onChange={handleUpdateVisible} className={visibleClass}/>
                            <div className="d-flex flex-row align-items-center">
                                {visible ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}
                                <div className={s.text}>VISIBILITY</div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </li>
    );
};

export default GalleryCard;
