import {lazy} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
//modals
import ModalGalleryCard from '../scenes/MyMetas/GalleryCard/GalleryCard';
//scenes
const Home = lazy(() => import('../scenes/Home/Home'));
const MyMetas = lazy(() => import('../scenes/MyMetas/MyMetas'));
const AvaTracksGrid = lazy(() => import('../scenes/AvaTracksGrid/AvaTracksGrid'));
const JigScenePuzzle = lazy(() => import('../scenes/JigScenePuzzle/JigScenePuzzle'));
const JigScenePuzzleMint = lazy(() => import('../scenes/JigScenePuzzle/JigScenePuzzleMint'));
const GalleryAssetPage = lazy(() => import('../scenes/GalleryAsset/GalleryAssetPage'));
const PageNotFound = lazy(() => import('../scenes/PageNotFound404/PageNotFound'));
const ATMPAge = lazy(() => import('../scenes/ATM/ATMPage'));
const AssetStatusPage = lazy(() => import('../scenes/AssetStatusPage/AssetStatusPage'));
const HoverblastPage = lazy(() => import('../scenes/Hoverblast/HoverblastPage'));

export const routes = {
    HOME: '/',
    ABOUT: '/#about',
    TEAM: '/#team',
    ROADMAP: '/#roadmap',
    EXPLORE_META: '/ANMT/Animetas/0',
    MINT: '/mint',
    MY_METAS: '/my_metas',
    GALLERY_ITEM: '/gallery/:id',
    JIGSCENE_PUZZLE: '/jigscene_puzzle',
    JIGSCENE_PUZZLE_MINT: '/mint_jigscene_puzzle',
    GALLERY_ASSET_PAGE: '/ANMT/:contractName/:tokenId',
    PAGE_NOT_FOUND: '/404',
    ATM: '/atm',
    AVA_TRACKS_GRID: '/ava_tracks',
    ASSET_STATUS_PAGE: '/status/ANMT/:contractName/:tokenId',
    HOVERBLAST: '/hoverblast'
};

export const socialRoutes = {
    TWITTER: "https://twitter.com/Animetas1",
    DISCORD: "https://t.co/8H9fAfZPS7?amp=1",
    INSTAGRAM: "https://www.instagram.com/animetas1/",
    GUSTAVO_TW: "https://twitter.com/PixelGustavo",
    CYBERH49_TW: "https://twitter.com/cyberh49",
    UM_LOLA_TW: "https://twitter.com/_um_lola",
    RAIPH_TW: "https://twitter.com/rAIph_4_real",
    D4BURNT_TW: "https://twitter.com/D4Burnt",
    MOSTEPH_TW: "https://twitter.com/_MoSteph",
    OROSE_TW: "https://twitter.com/__oRose__",
    RENT: 'https://animetas.renft.io/',
    TOOLS: "https://anitools.animetas.io/"
};

export const TeamMembersSocialRoutes = [
    {name: "Aeol", link: "https://twitter.com/Aeolus"},
    {name: "haberdasher", link: "https://twitter.com/W1lfredoGomez"},
    {name: "joepayne.eth", link: "https://twitter.com/joepayneco"},
    {name: "fccview", link: "https://twitter.com/fccview"},
    {name: "KTCryptoeth", link: "https://twitter.com/KTCrypto_eth"},
    {name: "fluffntuff.eth", link: "https://twitter.com/Fluffntuff"},
    {name: "Moondog", link: "https://twitter.com/MoondogIRL"},
    {name: "Frozendew", link: "https://twitter.com/Frozendew100"},
    {name: "Matimio", link: "https://twitter.com/MetaMatimio"},
    {name: "saintconfucius", link: "https://twitter.com/saintconfucius"},
    {name: "NatureNuts", link: "https://twitter.com/TheDylanHarwood"},
    {name: "AniNews1", link: "https://twitter.com/AniNews_1"},
    {name: "AnimetasArt", link: "https://twitter.com/AnimetasArt"},
];

const BaseRoutes = () => {
    const location = useLocation();
    const background = location.state?.background;

    return (
        <>
            <Switch location={background || location}>
                <Route path={routes.HOME} exact component={Home}/>
                <Route path={routes.MY_METAS} component={MyMetas}/>
                <Route path={routes.GALLERY_ITEM} component={ModalGalleryCard}/>
                <Route path={routes.JIGSCENE_PUZZLE} component={JigScenePuzzle}/>
                <Route path={routes.JIGSCENE_PUZZLE_MINT} component={JigScenePuzzleMint}/>
                <Route path={routes.GALLERY_ASSET_PAGE} component={GalleryAssetPage}/>
                <Route path={routes.PAGE_NOT_FOUND} component={PageNotFound}/>
                <Route path={routes.ATM} component={ATMPAge}/>
                <Route path={routes.AVA_TRACKS_GRID} component={AvaTracksGrid}/>
                <Route path={routes.ASSET_STATUS_PAGE} component={AssetStatusPage} />
                <Route path={routes.HOVERBLAST} component={HoverblastPage} />
                <Route component={PageNotFound}/>
            </Switch>

            {background && (
                <Route path={routes.GALLERY_ITEM} component={ModalGalleryCard}/>
            )}
        </>
    );
};

export default BaseRoutes;
