import './styles/main.scss';
import {Component, Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import BaseRoutes from './routes/BaseRoutes';
import Preloader from './components/UIElements/Preloader/Preloader';
import CacheBuster from "./components/CacheBuster";

// const App = () => {
//   return (
//     <Suspense fallback={<Preloader />}>
//       <Router>
//         <BaseRoutes />
//       </Router>
//     </Suspense>
//   );
// };

class App extends Component {
    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) {
                        return null;
                    }
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    }

                    return (
                        <Suspense fallback={<Preloader/>}>
                            <Router>
                                <BaseRoutes/>
                            </Router>
                        </Suspense>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default App;
